@import "Colorvariable.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
    background-color: $body-color !important;
    font-family: "Roboto", sans-serif !important;
}
.icon_primary{
    color:#f8ac60 ;
}
// winimage 
.pagination-controls{
    display: flex;
    justify-content: center;
    padding: 10px;
    .pagination{
        .page-link{
        background: #fa8d49;
        color: #fff;
        border-radius: 5px;
        font-size: 15px;
        padding: 5px;
    }
    .page-info{
        font-size:13px;
        color:#768096;
        padding:0px 5px;
    }
    }
}
.details_page{
    background: #f6f6f6;
    width: 50%;
    color: #605454;
    clip-path: polygon(0 0, 89% 0, 100% 100%, 0 100%);
    margin-bottom: 8px;
    font-size: 14px;
    text-align: left;
    padding: 5px;
}
.text-deatails{
    font-size:12px;
}
#check{
    width:20px;
    height:20px;
    border-radius: 50%;
}

#check[type="checkbox"] {
    width: 15px;
    height: 15px;
    accent-color: #f8ac60;
  }
  .card_custum {
    background-color: #fff;
    color: #c5c5c5;
}
.plusicon {
    font-size: 29px;
    border: 2px dashed #c5c5c5;
    color: #c5c5c5;
}

.need-info{
    font-size: 12px;
    margin-top: 12px;
    color: red;
}
.iconnew{
    position: absolute;
    left: 7px;
    top: 7px;
    font-size: 22px;
    color: red;
}
.withdrawinput{
    background:#ccc;
    border-radius: 25px!important;
    padding: 0.375rem 2.75rem!important;
}
.border-raidus-25{
    border-radius: 25px;
}
.withdraw-amt{
    border-radius:25px!important;
    text-align:center;
    padding:5px ;
    width:100%;
    background: #f8ac60!important;
    margin-top:12px;
}
.all{
    border: 1px solid #f8ac60;
    border-radius: 8px;
    font-size: 11px;
    display: inline-block;
    margin-bottom: -7px;
    padding: 0px 29px;
    line-height: 7px;
    display: flex;
    align-items: center;
    margin-top: 7px;
}
.withdrawamount{
    font-size:10px;
    color:#768096;
    strong{
        color:red;
    }
}
.winimage{
    background:url('../src/assets/images/win.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 87%;
    height: 100%;
    background-position: center;
    margin: auto;
}
.lossimage{
    background:url('../src/assets/images/loss.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 87%;
    height: 100%;
    background-position: center;
    margin: auto;
}
.model_custum{
    position:fixed;
    height:100%;
    z-index: 999999;
    overflow: hidden;
    width:100%;
    background:rgba(0,0,0,0.5);
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);


}
.winingamountperied{
    font-size: 11px;
    color: #1c1c1c;
}
.congrets{
    font-style: italic;
    text-shadow: 2px -3px 1px #313131;
}
.Sorrytext{
        color: #7c9cc2;
        font-size: 32px;
        font-weight: 700;
}
.Loss{
    color: #7c9cc2;
    position: absolute;
    bottom: 24%;
    left: 50%;
    font-weight: 700;
    font-size: 32px;
    transform: translate(-50%, -50%);
}
.result-name{
    color: #7c9cc2;
    font-size: 12px;
}
.result-color{
    font-size: 15px;
    width: 85px;
    background: linear-gradient(199deg, red 50%, violet 52%);
    border-radius: 5px;
    line-height: 1.5;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.result-time{
    background: linear-gradient(199deg, red 50%, violet 52%);
    padding: 2px;
    font-size: 12px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.result-type{
    font-size: 15px;
    width: 70px;
    background: linear-gradient(199deg, red 50%, violet 52%);
    border-radius: 5px;
    line-height: 1.5;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.period-css{
    position: absolute;
    bottom: 25%;
    left: 50%;
    color: #000;
    font-size: 14px;
    transform: translate(-50%, -50%);
}
.content_win {
    color: #fff;
    width: 59%;
    height: 50%;
    position: relative;
    margin: auto;
    top: 46%;
}
.content_loss {
    color: #fff;
    width: 75%;
    max-width: 300px;
    height: 50%;
    position: relative;
    margin: auto;
    top: 34%;
}
.close_times{
    border: 3px solid #fff;
    border-radius: 50%;
    position: absolute;
    bottom: 0px;
    width: 25px;
    height: 25px;
    color: #fff;
    top: 23px;
    right: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}
// winimage 
.select2_box {
    .select2-container--classic .select2-selection--single .select2-selection__clear,
    .select2-container--classic .select2-selection--single .select2-selection__arrow,
    .select2-container--classic .select2-selection--single .select2-selection__rendered,
    .select2-container .select2-selection--single {
        height: 38px !important;
        line-height: 38px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }    
    option{
        text-align:start;
    }
    
}
.myhistory{
    .icon_profile{
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #c76286;
        border-radius: 6px;
        color: #fff;
        font-size: 16px;
    }
    .status_wingo{

    
    .status{
        border: 1px solid #fd565c;
    padding: 0px 10px;
    font-size: 10px;
    border-radius: 2px;
    color: #fd565c;
    margin-bottom:0px;
    }
    p{
        color: #fd565c;
        font-size: 13px;
        margin-bottom: 0px;
        text-align: right;
    }
    }
    .text-sm-custum{
        font-size:12px
    }
    .details_{
        border: 1px solid #fd565c;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 9px;
    color: #fd565c;
    margin-bottom:0px;
    }
}
.bg-primary_custum{
    background:$primarycolor;
}
.margin-bottom-89{
    margin-bottom:89px;
}
.withdrawaltab {
    .react-tabs__tab.react-tabs__tab--selected {
        &:after {
            content: none;
        }
    }
    .react-tabs__tab.react-tabs__tab--selected {
        border-radius: 10px;
        background: $primarycolor;
    }
    .react-tabs__tab {
        margin-top: 15px;
        background: #fff;
        border: 1px solid transparent;
        border-bottom: none;
        position: relative;
        list-style: none;
        padding: 8px 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .card_custum{
        background-color: #fff;
        color: #c5c5c5;
       .plusicon{
        font-size: 29px;
        border: 2px dashed #c5c5c5;
        color: #c5c5c5;
       } 
    }
}
.list_withdraw {
    padding-left: 13px;

    p {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: -10px;
            top: 5px;
            width: 6px;
            height: 6px;
            border-radius: 2px;
            background: $primarycolor;
            transform: rotate(45deg);
        }
    }
}

.popup {
    position: fixed;
    bottom: -100%; /* Initially off-screen */
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: bottom 0.3s ease-in-out; /* Transition on the bottom property */
    width: 100%;
    z-index: 999;
}
.closebtn {
    height: 30px;
    display: flex;
    justify-content: center;
    background: #f2f4ff;
    align-items: center;
    text-transform: capitalize;
    color: #7d7d9d;
    -webkit-box-flex: 1;
    flex: 1;
}
.totalamount {
    flex: 2 1;
    height: 30px;
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
}
.popup-inner {
    text-align: center;
}

.popupbottomtotop {
    background: #fff;
    position: absolute;
    width: 100%;
    bottom: 0px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.popup p {
    margin: 0;
}
.overlay_canvass {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(3, 0, 0, 0.308);
    z-index: -6;
}
.popup.active {
    bottom: 0; /* Slide the popup into view */
}

//  ******************************
// Common Section start
//  ******************************
.color_icon {
    color: $icon_color !important;
    font-size: 20px;
}
.register_btn_page {
    background: $oragnecolor;
    padding: 8px;
    border-radius: 25px;
    color: #fff;
    a {
        color: #fff;
        text-decoration: none;
        font-size: 19px;
        letter-spacing: 2px;
    }
}
.bg_color_all {
    background: $bg-section-color;
}
.Invitation_link {
    background: $buttoncolor;
    width: 100%;
    color: #fff;
    display: inline-block;
    border-radius: 25px;
    padding: 7px;
    text-decoration: none;
    box-shadow: inset 0px 9px 0px 0px rgba(255, 255, 255, 0.15);
    margin-top: 10px;
}

.agree_check {
    .form-check-label {
        display: flex;
    }
}

.lan_flag {
    width: 32px;
    height: 21px;
}

.register {
    color: orange;
}

.send_button {
    background: $oragnecolor !important;
    border-radius: 24px !important;
    position: absolute !important;
    right: 5px !important;
    color: #fff !important;
    padding: 4px 13px !important;
    border-color: $oragnecolor !important;
}

.send_button1 {
    background: #ff9742 !important;
    border-radius: 24px !important;
    position: absolute !important;
    right: 15px !important;
    color: #fff !important;
    padding: 4px 13px !important;
    border-color: #ff9742 !important;
    top: 271px !important;
}

.padding_all {
    // padding: 0px 0px 80px;
}
.heading {
    .box {
        position: relative;
        width: 300px;
        height: 41px;
        margin-left: 10px;
        margin-top: 15px;
        .trophy {
            position: absolute;
            left: -12px;
            top: 5px;
            color: #fff;
            z-index: 1;
        }
    }
    p {
        font-size: 16px;
        text-align: left;
        margin-bottom: 0px;
        position: relative;
        padding-left: 15px;
        padding-top: 0px;
        font-weight: 600;
    }
    .design_heading {
        display: block;
        height: 2px;
        width: 171px;
        background: $primarycolor;
        left: -5px;
        position: absolute;
        bottom: -10px;
        border-radius: 13px;
        &:before {
            content: "";
            position: absolute;
            left: 0px;
            width: 2px;
            height: 17px;
            top: -16px;
            background: $primarycolor;
        }
    }
    .box::after {
        content: "";
        position: absolute;
        width: 25px;
        height: 25px;
        bottom: 15px;
        left: -16px;
        border-radius: 5px;
        background: $primarycolor;
    }
}
.button_color {
    background: $buttoncolor;
    font-size: 15px;
    padding: 1px 10px;
    color: #fff;
    font-weight: 600;
}
.bg-color-all {
    background: $primarycolor;
}
.bg-color-tag {
    background: $tag_copy;
    padding: 5px 10px;
    margin-bottom: 8px;
}
.bg-color-tag.padding_custum_account {
    padding: 3px;
}
//  ******************************
// common Section end
//  ******************************

//  ******************************
// Login Section start
//  ******************************
.Login_section {
    .Login {
        background: $primarycolor;
        .Login_heading {
            padding-bottom: 30px;
            h1 {
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 6px;
                margin-top: 10px;
            }
            p {
                font-size: 11px;
            }
        }
    }
    .eye {
     position: absolute;
     right: 16px;
     top: 38px; 
    color: #aa9f9fad;
      }
    .login_method {
        .react-tabs__tab::after {
            content: none;
        }
        .react-tabs__tab-list {
            border-bottom: 0px solid #aaa;
            display: flex;
            justify-content: space-around;
        }
        .react-tabs__tab--selected {
            background: none;
            border-color: #aaa0;
            color: black;
            border-bottom: 2px solid $icon_color;
        }
    }
    .react-tabs__tab--selected .width-tab {
        .icon_login {
            svg {
                color: $icon_color;
            }
        }
        h6 {
            color: $icon_color;
        }
    }
    .width-tab {
        width: 100%;
        .icon_login {
            display: flex;
            justify-content: center;
            width: 100%;
            font-size: 23px;
            margin-bottom: 7px;
        }
        h6 {
            font-size: 13px;
            font-weight: 400;
            color: #666;
            text-align: center;
        }
    }
    .Login_form {
        form {
            padding-top: 30px;
            .input_login_radius_custum {
                width: 100%;
                height: 1.17333rem;
                padding: 20px 14px;
                color: #888;
                font-size: 13px;
                border: none;
                border-radius: 16px !important;
                height: 1.17333rem;
                padding: 20px 14px;
                color: #888;
                font-size: 13px;
                border: none;
                border-radius: 16px !important;
                width: 100%;
                box-shadow: 0 0.05333rem 0.21333rem rgba(208, 208, 237, 0.3607843137);
            }
            .label_login {
                svg {
                    margin-right: 5px;
                    color: $icon_color;
                }
            }
            .login_btn {
                background: linear-gradient(180deg, #817970 0%, #9799a2f7 100%);
                padding: 8px;
                border-radius: 25px;
                color: #fff;
                cursor: pointer;
                font-weight: 600;
                .login {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .register_btn {
                border: 1px solid $icon_color;
                padding: 8px;
                border-radius: 25px;
                color: $icon_color;
                margin-top: 21px;
                font-weight: 600;
                span {
                    color: gray;
                    text-decoration: none !important;
                    font-size: 14px;
                    letter-spacing: 2px;
                }
            }
            .ac {
                text-decoration: none;
            }
        }
        .support {
            div {
                .icon {
                    font-size: 30px;
                    color: $icon_color;
                }
            }
            span {
                font-size: 12px;
            }
        }
    }
}

//  ******************************
// Login Section end
//  ******************************

//  ******************************
// verification Section start
//  ******************************
.verification {
    padding: 20px 0px;
    .input_login_radius_custum {
        width: 100%;
        height: 1.17333rem;
        padding: 20px 14px;
        color: #888;
        font-size: 13px;
        border: none;
        border-radius: 16px !important;
        height: 1.17333rem;
        padding: 20px 14px;
        color: #888;
        font-size: 13px;
        border: none;
        border-radius: 16px !important;
        width: 100%;
        box-shadow: 0 0.05333rem 0.21333rem rgb(107 107 107 / 62%);
    }
    .resendcode {
        background: $buttoncolor;
        width: 100%;
        padding: 0px 22px;
        color: #fff;
        border-radius: 38px;
    }
    .alert_custum {
        padding: 10px;
        p {
            font-size: 12px;
        }
    }
    .verficationnumber {
        svg {
            color: $icon_color;
        }
        p {
            font-size: 14px;
        }
    }
    .custumer_services {
        padding: 10px;
        border-radius: 25px;
        border: 1px solid $primarycolor;
    }
}
//  ******************************
// verification Section end
//  ******************************

//  ******************************
// Header Section start
//  ******************************
.Header_section {
    background: #f7f8ff;
    padding: 9px 0px;
    .logo {
        img {
            width: 60px;
        }
    }
    .header_icon {
        svg {
            margin: 0px 5px;
            background: $buttoncolor;
            border-radius: 3px;
            padding: 2px;
            color: #fff;
            font-size: 16px;
        }
    }
}
//  ******************************
// Header Section end
//  ******************************

//  ******************************
// Promotion Section start
//  ******************************
.Promotion {
    .Linksall {
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0.05333rem 0.21333rem rgba(208, 208, 237, 0.3607843137);
        overflow: hidden;
        margin: 13px 0px 0px;
        padding: 0px 8px 0px;
        .promotion_setting {
            .amount_new {
                font-weight: bold;
                font-size: 14px;
            }
            font-size: 12px;
        }
    }
    .Links_all {
        background: #fff;
        padding: 11px 7px;
        border-bottom: 1px solid #e6e8e8;
        .text-custum-color {
            color: #666;
        }
    }
    .bg_color_page_home {
        background: $primarycolor;
        margin-bottom: 120px;
        padding: 10px 0px 120px;
        position: relative;

        .bg-color-tag {
            background: $tag_copy;
            padding: 5px 10px;
            margin-bottom: 8px;
        }
        .subcordinater {
            background: #fff;
            border-radius: 9px;
            width: 89%;
            margin: auto;
            overflow: hidden;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -4%);
            .subcordinaterone,
            .subcordinatersec {
                .details_subcoridnater {
                    border-right: 0.01333rem solid #e8e8e8;
                    margin-top: 10px;
                    padding: 0px 2px;
                    h6,
                    p {
                        margin-bottom: 3px;
                        padding-top: 0.2rem;
                        font-size: 11px;
                        line-height: 12px;
                    }
                    p {
                        font-size: 10px;
                        color: #888;
                    }
                }
                .card-sub {
                    padding: 8px;
                    background-color: #f6f6f6;
                    h5 {
                        font-size: 10px;
                    }
                }
                svg {
                    margin-right: 5px;
                    color: $icon_color;
                }
            }
        }
    }
    .Invitation_link {
        background: $buttoncolor;
        width: 100%;
        color: #fff;
        display: inline-block;
        border-radius: 25px;
        padding: 7px;
        text-decoration: none;
        box-shadow: inset 0px 9px 0px 0px rgb(255 255 255 / 15%);
    }
    .invitaion_tab {
        .card_custum_new {
            padding: 10px 15px;
            margin: 10px 0px;
            .invitation_tab_all {
                .btn_icon {
                    font-size: 23px;
                    color: $icon_color;
                }
                p {
                    font-size: 12px;
                    color: #888;
                    margin-left: 10px;
                }
                svg.right-icon {
                    color: #888;
                    font-size: 19px;
                    margin-left: 8px;
                }
            }
        }
    }
}

//  ******************************
// Promotion Section end
//  ******************************
//  ******************************
// Wallet Section start
//  ******************************
.wallet {
    .wallet_Details {
        padding: 10px 30px 30px;
    }

    .wallet_Details {
        .totalamount {
            font-size: 12px;
        }
    }
    .wallet_transfer {
        .circlegraph {
            width: 100px;
            height: 100px;
            margin-bottom: 13px;
        }
        .wallet_type {
            font-size: 10px;
            color: #000;
        }
        h3.wallet_type {
            font-size: 15px;
        }
    }
    .walleticon_de {
        .icon_all {
            width: 35px;
            height: 35px;
            margin: 9px auto 0px;
            display: flex;
            justify-content: center;
            background: white;
            align-items: center;
            border-radius: 5px;
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
            font-size: 21px;
            svg {
                color: var(--i);
            }
        }
        font-size: 12px;
        color: #666;
    }
    .wallet_transfer {
        position: relative;
        top: -21px;
    }
    .box_custum {
        box-shadow: 0 0.10667rem 0.21333rem rgba(208, 208, 237, 0.3607843137);
        border-radius: 7px;
        padding: 15px 15px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            font-size: 12px;
            color: #666;
            margin-bottom: 0px;
        }
        h2 {
            font-size: 13px;
            color: #666;
        }
    }
}
//  ******************************
// Wallet Section end
//  ******************************

//  ******************************
// homesec Section start
//  ******************************
.Homesec {
    .Notice {
        p {
            width: 90%;
            height: fit-content;
            max-height: 22px;
            line-height: 1.1;
            animation: marquee 7s linear infinite;
            display: flex;
            justify-content: column;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            word-break: break-all;
            font-size: 10px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 0px;
        }
    }
    .Marquee_Tag {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        width: 100%;
        height: 39px;
        padding-inline: 0.26667rem;
        color: #303a4c;
        font-size: 0.32rem;
        border-radius: 99rem;
        background: #fff;
        box-shadow: 0 0.10667rem 0.16rem #d0d0ed4d;
        padding: 9px;
        border-radius: 27px;
        margin-bottom: 10px;
        .sound_icon {
            font-size: 20px;
            color: $footerdeactive;
        }
        .details {
            padding: 2px 13px;
            border-radius: 27px;
            flex-shrink: 0;
            gap: 11px;
            margin-left: auto;
            border: none;
            background: $buttoncolor;
            font-size: 22px;
            align-items: center;
            display: flex;
            height: 25px;
            justify-content: center;
            button {
                font-size: 11px;
                align-items: center;
                gap: 4px;
            }
        }
    }
}
//  ******************************
// home Section end
//  ******************************
//  ******************************
// account Section start
//  ******************************
//  ******************************
// Activity Section end
//  ******************************
.Activity {
    .bg_color_all {
        background: $bg-section-color;
        padding: 15px 0px;
    }
    .logoactivity {
        width: 80px;
        margin: auto;
    }
    .Activity_details {
        h3 {
            margin-bottom: 5px;
            color: #fff;
            font-size: 13px;
        }
        p {
            font-size: 10px;
            color: #fff;
            margin-bottom: 0px;
        }
    }
    .icon_new {
        text-align: center;
        h3 {
            font-size: 12px;
            color: #666;
        }
        .icon_image {
            width: 30px;
            height: 30px;
            margin: auto;
            align-items: center;
            background: $icon_gradient;
            border-radius: 5px;
            margin-top: 10px;
            margin-bottom: 5px;
            color: #fff;
        }
    }
    .reedem_details {
        overflow: hidden;
        img {
            width: 200px;
            height: 85px;
            object-fit: cover;
        }

        .reedem {
            padding: 8px;
            h3 {
            }
        }
    }
    .banner_game {
        overflow: hidden;
        .banner_image {
            width: 100%;
            height: 130px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .reedem.card-body {
            padding: 8px;
        }
    }
}
//  ******************************
// Activity Section start
//  ******************************
.Account {
    .totalbalance {
        padding: 15px 20px;
        position: relative;
        &:after,
        &:before {
            content: "";
            width: 20px;
            height: 20px;
            background-color: #f9f3f3;
            position: absolute;
            top: 37%;
            border-radius: 50%;
        }
        &:after {
            left: -8px;
        }
        &:before {
            right: -8px;
        }
        .totalbalance_border {
            border-bottom: 2px solid transparent;
            border-image: $primarycolor;
            border-image-slice: 1;
            width: 100%;
        }
    }
    .padding_custum_history {
        padding: 15px 4px;
    }
    .game_history {
        .game_history_icon {
            svg {
                font-size: 31px;
            }
        }
        .content_history {
            margin-left: 10px;
            h6 {
                font-size: 14px;

                span {
                    font-size: 10px;
                    display: block;
                    color: #888;
                    text-decoration: none !important;
                }
            }
        }
    }
    .walleticon_de {
        .icon_all {
            width: 35px;
            height: 35px;
            margin: 9px auto 0px;
            display: flex;
            justify-content: center;
            background: white;
            align-items: center;
            border-radius: 5px;
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
            font-size: 21px;
            svg {
                color: var(--i);
            }
        }
        font-size: 12px;
        color: #666;
    }
    .account_details {
        padding-top: 20px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        &:before {
            content: "";
            width: 100%;
            left: 0px;
            top: 0px;
            position: absolute;
            height: 190px;
            z-index: -1;
            border-bottom-right-radius: 25px;
            border-bottom-left-radius: 25px;
            background: $primarycolor;
        }
        .userdetails {
            text-align: start;
            margin-left: 20px;
            margin-bottom: 10px;
            h3 {
                color: #fff;
                font-size: 15px;
                margin-bottom: 0px;
            }
            .uid_number {
                margin-bottom: 0px;
                width: 77%;
            }
        }
    }
    .userinofologin {
        span {
            line-height: 1;
            font-size: 13px;
            color: #fff;
        }
    }
    .Linksall {
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0.05333rem 0.21333rem #d0d0ed5c;
        overflow: hidden;
        margin: 13px 0px 0px;
        padding: 0px 8px 0px;
    }
    .Links_all {
        background: #fff;
        padding: 11px 7px;
        border-bottom: 1px solid #e6e8e8;
        .text-custum-color {
            color: #666;
        }
    }
}
//  ******************************
// account Section end
//  ******************************
//  ******************************
// Wingo Section end
//  ******************************
// offcanvas css
.offcanvas_custum {
    width: 100%;
    height: 100%;
    background: #00000096;

    .counterbtnsd {
        background: $oragnecolor;
        border-radius: 3px;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .inputcount {
        width: 68px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 2px;
        margin: 0px 5px;
    }
    .bg_color_header {
        // background: $primarycolor;
        padding-bottom: 42px;
        position: relative;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        &:before {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            width: 50%;
            height: 3.78667rem;
            padding-top: 0.4rem;
            background-image: -webkit-linear-gradient(99deg, #fff 50%, transparent 50%);
            background-image: linear-gradient(-9deg, #fff 50%, transparent 50%);
        }
        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 50%;
            height: 3.78667rem;
            padding-top: 0.4rem;
            background-image: -webkit-linear-gradient(81deg, #fff 50%, transparent 50%);
            background-image: linear-gradient(9deg, #fff 50%, transparent 50%);
        }
        .titlegame {
            padding: 10px 0px 0px;
        }
        .offcanvas-title.h5 {
            width: 100%;
            text-align: center;
        }
    }
    .btn_amt {
        background: #f0f0f0;
        padding: 2px 5px;
        margin: 0px 2px;
        border-radius: 5px;
    }
    .active_btn {
        background: $oragnecolor;
        color: #fff;
    }
    .titlegame {
        h3 {
            font-size: 15px;
            margin-bottom: 6px;
        }
        h4 {
            font-size: 12px;
            background-color: #fff;
            margin-bottom: 0px;
            padding: 5px;
            border-radius: 6px;
            width: 80%;
            margin: 0px auto 0px;
        }
    }
}
// offcanvas css
//  ******************************
// wingo Section start
//  ******************************
.bg-image-header-rule {
    background: url("../src/assets/images/d.png");
    background-size: cover;
    height: 100%;
    width: 100%;
    background-position: 100% 66%;
}
.inkow_footer {
    .iknow {
        background: $primarycolor !important;
    }
}
.wingosection {
    position: relative;
    // top: -70px;
    .remainingtime{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #00000054;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        display:flex;
        h3{
            font-size: 80px;
            padding: 0px 20px;
            background: #fb494f;
            color: #fff;
            border-radius: 5px;
        }
        }
    .wingo {
        .button_color_big_small {
            width: 100%;
            height: 30px;
            text-transform: capitalize;
            color: #fff;
            &:nth-child(1) {
                border-top-left-radius: 25px;
                border-bottom-left-radius: 25px;
                background-color: #fd565c;
            }
            &:nth-child(2) {
                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;
                background-color: #6ea8f4;
            }
        }

        .multiple_win {
            border: 1px solid $oragnecolor;
            color: $oragnecolor;
            padding: 0px 5px;
            border-radius: 4px;
            font-size: 13px;
        }
        .active.multiple_win {
            font-size: 13px;
            background: $primarycolor;
            border: 1px solid $oragnecolor;
            color: #fff;
        }
        .multiple_winredom {
            padding: 4px 15px;
            border-radius: 4px;
            font-size: 13px;
        }
        .button_color_game {
            padding: 4px 32px;
            color: #fff;
            &:nth-child(1) {
                background: #40ad72;
                border-top-left-radius: 5px;
                border-bottom-right-radius: 5px;
                box-shadow: 0 0.05333rem 0.16rem rgba(244, 157, 157, 0.144);
            }
            &:nth-child(2) {
                background: #b659fe;
                border-radius: 5px;
                box-shadow: 0 0.05333rem 0.16rem rgba(244, 157, 157, 0.192);
            }
            &:nth-child(3) {
                background: #fd565c;
                border-top-left-radius: 5px;
                box-shadow: 0 0.05333rem 0.16rem #f49d9d;
                border-bottom-right-radius: 5px;
            }
        }
        .Marquee_Tag {
            display: -webkit-box;
            display: -webkit-flex;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            align-items: center;
            width: 100%;
            height: 39px;
            padding-inline: 0.26667rem;
            color: #303a4c;
            font-size: 0.32rem;
            border-radius: 99rem;
            background: #fff;
            box-shadow: 0 0.10667rem 0.16rem #d0d0ed4d;
            padding: 9px;
            border-radius: 27px;
            margin-bottom: 10px;
            margin-top: 10px;
            .Notice p {
                width: 90%;
                height: -moz-fit-content;
                height: fit-content;
                max-height: 22px;
                line-height: 1.1;
                animation: marquee 7s linear infinite;
                display: flex;
                justify-content: column;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
                word-break: break-all;
                font-size: 10px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-bottom: 0px;
            }

            .sound_icon {
                font-size: 20px;
                color: $footerdeactive;
            }
            .details {
                padding: 2px 13px;
                border-radius: 27px;
                flex-shrink: 0;
                gap: 11px;
                margin-left: auto;
                border: none;
                background: $buttoncolor;
                font-size: 22px;
                align-items: center;
                display: flex;
                height: 25px;
                justify-content: center;
                button {
                    font-size: 11px;
                    align-items: center;
                    gap: 4px;
                }
            }
        }
        .allbefore:before {
            position: absolute;
            left: 0px;
            width: 100%;
            top: -8px;
            // background: $primarycolor;
            height: 250px;
            content: "";
            border-bottom-right-radius: 25px;
            border-bottom-left-radius: 25px;
            z-index: -1;
        }
        .buttonall {
            background: var(--bgcolor);
            font-weight: 600;
            color: #fff;
            text-align: center;
            padding: 2px 10px;
            margin-top: 10px;
        }
        .points_left_right {
            position: relative;
            &:after,
            &:before {
                border: 5px solid $oragnecolor;
                content: "";
                width: 20px;
                height: 20px;
                background-color: #f9f3f3;
                position: absolute;
                top: 37%;
                border-radius: 50%;
            }
            &:after {
                left: -8px;
            }
            &:before {
                right: -8px;
            }
        }
    }
    .windgo_tabs {
        .react-tabs {
            ul {
                .react-tabs__tab--selected {
                    box-shadow:
                        inset 0 -0.02667rem 0.08rem #ffdfdb,
                        inset 0 0.02667rem 0.08rem #ea6b66;
                    background: $primarycolor;
                    border-color: transparent;
                    border-radius: 10px;
                    color: black;
                    .tabs_windgo > * {
                        color: #fff;
                    }
                }
                li {
                    padding: 7px;
                    width: 75px;
                }
            }
        }
        .icon_rounded {
            font-size: 30px;
            opacity: 0.8;
        }
        p {
            font-size: 11px;
            color: #666;
            margin-bottom: 0px;
            line-height: 15px;
            span {
                display: block;
            }
        }
        .border_offset {
            outline: 2px dotted #fff;
            outline-offset: -5px;
        }
        .tab_active {
            .Howtoplay {
                h5 {
                    color: #fff;
                    font-size: 12px;
                    border: 1px solid #fff;
                    border-radius: 25px;
                    padding: 3px 10px;
                }
                p {
                    margin-top: 5px;
                    color: #fff;
                    font-size: 12px;
                    text-align: start;
                }
            }
            
            .Countdown_timer {
                .countdown_time {
                    .countdown {
                        padding: 0px 5px;
                        background: #fff;
                        &:nth-child(1) {
                            border-radius: 1px;
                            border-top-left-radius: 6px;
                        }
                        &:nth-child(5) {
                            border-radius: 1px;
                            border-bottom-right-radius: 6px;
                        }
                    }
                }
            }
        }
    }
    .history {
        .react-tabs__tab-list {
            display: flex;
            border-bottom: 0px;
            justify-content: space-between;
            .react-tabs__tab {
                padding: 0px;
                button {
                    border-radius: 5px;
                    border: 1px solid $oragnecolor;
                    color: $oragnecolor;
                    font-size: 13px;
                    width: 100px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .react-tabs__tab--selected {
                background: $primarycolor;
                border-color: transparent;
                color: black;
                border-radius: 5px;
                &:after {
                    content: none;
                }
                button {
                    width: 100px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    color: #fff;
                    font-size: 13px;
                }
            }
        }
        .history_table {
            thead {
                tr {
                    th {
                        padding: 5px;
                        font-weight: 600;
                        font-size: 14px;
                        background: $primarycolor;
                        color: #fff;
                        &:nth-child(1) {
                            border-top-left-radius: 5px;
                        }

                        &:nth-child(4) {
                            border-top-right-radius: 5px;
                        }
                    }
                }
            }
            tbody {
                td {
                    padding: 5px;
                    font-size: 13px;
                    vertical-align: middle;
                    span.number {
                        font-size: 18px;
                        font-weight: bold;
                        background: linear-gradient(180deg, #40ad72 51.48%, #b659fe 51.49%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }
                }
            }
        }
    }
}
.wingochart {
    .windgo_tabs {
        .react-tabs {
            ul {
                .react-tabs__tab--selected {
                    box-shadow:
                        inset 0 -0.02667rem 0.08rem #ffdfdb,
                        inset 0 0.02667rem 0.08rem #ea6b66;
                    background: $primarycolor;
                    border-color: transparent;
                    border-radius: 10px;
                    color: black;
                    .tabs_windgo > * {
                        color: #fff;
                    }
                }
                li {
                    padding: 7px;
                    width: 75px;
                }
            }
        }
        .icon_rounded {
            font-size: 30px;
            opacity: 0.8;
        }
        p {
            font-size: 11px;
            color: #666;
            margin-bottom: 0px;
            line-height: 15px;
            span {
                display: block;
            }
        }
        .border_offset {
            outline: 2px dotted #fff;
            outline-offset: -5px;
        }
        .tab_active {
            .Howtoplay {
                h5 {
                    color: #fff;
                    font-size: 12px;
                    border: 1px solid #fff;
                    border-radius: 25px;
                    padding: 3px 10px;
                }
                p {
                    margin-top: 5px;
                    color: #fff;
                    font-size: 12px;
                    text-align: start;
                }
            }
            .Countdown_timer {
                .countdown_time {
                    .countdown {
                        padding: 0px 5px;
                        background: #fff;
                        &:nth-child(1) {
                            border-radius: 1px;
                            border-top-left-radius: 6px;
                        }
                        &:nth-child(5) {
                            border-radius: 1px;
                            border-bottom-right-radius: 6px;
                        }
                    }
                }
            }
        }
    }
    .history {
        .react-tabs__tab-list {
            display: flex;
            border-bottom: 0px;
            justify-content: space-between;
            .react-tabs__tab {
                padding: 0px;
                button {
                    border-radius: 5px;
                    border: 1px solid $oragnecolor;
                    color: $oragnecolor;
                    font-size: 13px;
                    width: 100px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .react-tabs__tab--selected {
                background: $primarycolor;
                border-color: transparent;
                color: black;
                border-radius: 5px;
                &:after {
                    content: none;
                }
                button {
                    width: 100px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    color: #fff;
                    font-size: 13px;
                }
            }
        }
        .history_table {
            thead {
                tr {
                    th {
                        padding: 10px 5px;
                        font-weight: 500;
                        font-size: 14px;
                        background: $primarycolor;
                        color: #fff;
                        &:nth-child(1) {
                            border-top-left-radius: 5px;
                        }

                        &:nth-child(4) {
                            border-top-right-radius: 5px;
                        }
                    }
                }
            }
            tbody {
                td {
                    padding: 5px;
                    font-size: 13px;
                    vertical-align: middle;
                    span.number {
                        font-size: 18px;
                        font-weight: bold;
                        background: linear-gradient(180deg, #40ad72 51.48%, #b659fe 51.49%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }
                }
            }
        }
    }
}
.wingoheader {
    .windgo_tabs {
        .react-tabs {
            ul {
                .react-tabs__tab--selected {
                    box-shadow:
                        inset 0 -0.02667rem 0.08rem #ffdfdb,
                        inset 0 0.02667rem 0.08rem #ea6b66;
                    background: $primarycolor;
                    border-color: transparent;
                    border-radius: 10px;
                    color: black;
                    .tabs_windgo > * {
                        color: #fff;
                    }
                }
                li {
                    padding: 7px;
                    width: 75px;
                }
            }
        }
        .icon_rounded {
            font-size: 30px;
            opacity: 0.8;
        }
        p {
            font-size: 11px;
            color: #666;
            margin-bottom: 0px;
            line-height: 15px;
            span {
                display: block;
            }
        }
        .border_offset {
            outline: 2px dotted #fff;
            outline-offset: -5px;
        }
        .tab_active {
            .Howtoplay {
                h5 {
                    color: #fff;
                    font-size: 12px;
                    border: 1px solid #fff;
                    border-radius: 25px;
                    padding: 3px 10px;
                }
                p {
                    margin-top: 5px;
                    color: #fff;
                    font-size: 12px;
                    text-align: start;
                }
            }
            .Countdown_timer {
                .countdown_time {
                    .countdown {
                        padding: 0px 5px;
                        background: #fff;
                        &:nth-child(1) {
                            border-radius: 1px;
                            border-top-left-radius: 6px;
                        }
                        &:nth-child(5) {
                            border-radius: 1px;
                            border-bottom-right-radius: 6px;
                        }
                    }
                }
            }
        }
    }
    .history {
        .react-tabs__tab-list {
            display: flex;
            border-bottom: 0px;
            justify-content: space-between;
            .react-tabs__tab {
                padding: 0px;
                button {
                    border-radius: 5px;
                    border: 1px solid $oragnecolor;
                    color: $oragnecolor;
                    font-size: 13px;
                    width: 100px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .react-tabs__tab--selected {
                background: $primarycolor;
                border-color: transparent;
                color: black;
                border-radius: 5px;
                &:after {
                    content: none;
                }
                button {
                    width: 100px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    color: #fff;
                    font-size: 13px;
                }
            }
        }
        .history_table {
            thead {
                tr {
                    th {
                        padding: 5px;
                        font-weight: 600;
                        font-size: 14px;
                        background: $primarycolor;
                        color: #fff;
                        &:nth-child(1) {
                            border-top-left-radius: 5px;
                        }

                        &:nth-child(4) {
                            border-top-right-radius: 5px;
                        }
                    }
                }
            }
            tbody {
                td {
                    padding: 5px;
                    font-size: 13px;
                    vertical-align: middle;
                    span.number {
                        font-size: 18px;
                        font-weight: bold;
                        background: linear-gradient(180deg, #40ad72 51.48%, #b659fe 51.49%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }
                }
            }
        }
    }
    .wingo {
        .button_color_big_small {
            width: 100%;
            height: 30px;
            text-transform: capitalize;
            border-radius: 25px;
            color: #fff;
            &:nth-child(1) {
                background-color: #fd565c;
            }
            &:nth-child(2) {
                background-color: #c76286;
            }
        }

        .multiple_win {
            border: 1px solid $oragnecolor;
            color: $oragnecolor;
            padding: 0px 5px;
            border-radius: 4px;
            font-size: 13px;
        }
        .active.multiple_win {
            font-size: 13px;
            background: $primarycolor;
            border: 1px solid $oragnecolor;
            color: #fff;
        }
        .multiple_winredom {
            padding: 4px 15px;
            border-radius: 4px;
            font-size: 13px;
        }
        .button_color_game {
            padding: 4px 32px;
            color: #fff;
            &:nth-child(1) {
                background: #40ad72;
                border-top-left-radius: 5px;
                border-bottom-right-radius: 5px;
                box-shadow: 0 0.05333rem 0.16rem rgba(244, 157, 157, 0.144);
            }
            &:nth-child(2) {
                background: #b659fe;
                border-radius: 5px;
                box-shadow: 0 0.05333rem 0.16rem rgba(244, 157, 157, 0.192);
            }
            &:nth-child(3) {
                background: #fd565c;
                border-top-left-radius: 5px;
                box-shadow: 0 0.05333rem 0.16rem #f49d9d;
                border-bottom-right-radius: 5px;
            }
        }
        .Marquee_Tag {
            display: -webkit-box;
            display: -webkit-flex;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            align-items: center;
            width: 100%;
            height: 39px;
            padding-inline: 0.26667rem;
            color: #303a4c;
            font-size: 0.32rem;
            border-radius: 99rem;
            background: #fff;
            box-shadow: 0 0.10667rem 0.16rem #d0d0ed4d;
            padding: 9px;
            border-radius: 27px;
            margin-bottom: 10px;
            margin-top: 10px;
            .Notice p {
                width: 90%;
                height: -moz-fit-content;
                height: fit-content;
                max-height: 22px;
                line-height: 1.1;
                animation: marquee 7s linear infinite;
                display: flex;
                justify-content: column;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
                word-break: break-all;
                font-size: 10px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-bottom: 0px;
            }

            .sound_icon {
                font-size: 20px;
                color: $footerdeactive;
            }
            .details {
                padding: 2px 13px;
                border-radius: 27px;
                flex-shrink: 0;
                gap: 11px;
                margin-left: auto;
                border: none;
                background: $buttoncolor;
                font-size: 22px;
                align-items: center;
                display: flex;
                height: 25px;
                justify-content: center;
                button {
                    font-size: 11px;
                    align-items: center;
                    gap: 4px;
                }
            }
        }
        .allbefore:before {
            position: absolute;
            left: 0px;
            width: 100%;
            top: -8px;
            background: $primarycolor;
            height: 250px;
            content: "";
            border-bottom-right-radius: 25px;
            border-bottom-left-radius: 25px;
            z-index: -1;
        }
        .buttonall {
            background: var(--bgcolor);
            font-weight: 600;
            color: #fff;
            text-align: center;
            padding: 6px 29px;
            margin-top: 8px;
        }
        .points_left_right {
            position: relative;
            &:after,
            &:before {
                border: 5px solid $oragnecolor;
                content: "";
                width: 20px;
                height: 20px;
                background-color: #f9f3f3;
                position: absolute;
                top: 37%;
                border-radius: 50%;
            }
            &:after {
                left: -8px;
            }
            &:before {
                right: -8px;
            }
        }
        .windgo_tabs {
            .react-tabs {
                ul {
                    .react-tabs__tab--selected {
                        box-shadow:
                            inset 0 -0.02667rem 0.08rem #ffdfdb,
                            inset 0 0.02667rem 0.08rem #ea6b66;
                        background: $primarycolor;
                        border-color: transparent;
                        border-radius: 10px;
                        color: black;
                        .tabs_windgo > * {
                            color: #fff;
                        }
                    }
                    li {
                        padding: 7px;
                        width: 75px;
                    }
                }
            }
            .icon_rounded {
                font-size: 30px;
                opacity: 0.8;
            }
            p {
                font-size: 11px;
                color: #666;
                margin-bottom: 0px;
                line-height: 15px;
                span {
                    display: block;
                }
            }
            .border_offset {
                outline: 2px dotted #fff;
                outline-offset: -5px;
            }
            .tab_active {
                .Howtoplay {
                    h5 {
                        color: #fff;
                        font-size: 12px;
                        border: 1px solid #fff;
                        border-radius: 25px;
                        padding: 3px 10px;
                    }
                    p {
                        margin-top: 5px;
                        color: #fff;
                        font-size: 12px;
                        text-align: start;
                    }
                }
                .Countdown_timer {
                    .countdown_time {
                        .countdown {
                            padding: 0px 5px;
                            background: #fff;
                            &:nth-child(1) {
                                border-radius: 1px;
                                border-top-left-radius: 6px;
                            }
                            &:nth-child(5) {
                                border-radius: 1px;
                                border-bottom-right-radius: 6px;
                            }
                        }
                    }
                }
            }
        }
        .history {
            .react-tabs__tab-list {
                display: flex;
                border-bottom: 0px;
                justify-content: space-between;
                .react-tabs__tab {
                    padding: 0px;
                    button {
                        border-radius: 5px;
                        border: 1px solid $oragnecolor;
                        color: $oragnecolor;
                        font-size: 13px;
                        width: 100px;
                        height: 34px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .react-tabs__tab--selected {
                    background: $primarycolor;
                    border-color: transparent;
                    color: black;
                    border-radius: 5px;
                    &:after {
                        content: none;
                    }
                    button {
                        width: 100px;
                        height: 34px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px;
                        border: 1px solid #ccc;
                        color: #fff;
                        font-size: 13px;
                    }
                }
            }
            .history_table {
                thead {
                    tr {
                        th {
                            padding: 5px;
                            font-weight: 600;
                            font-size: 14px;
                            background: $primarycolor;
                            color: #fff;
                            &:nth-child(1) {
                                border-top-left-radius: 5px;
                            }

                            &:nth-child(4) {
                                border-top-right-radius: 5px;
                            }
                        }
                    }
                }
                tbody {
                    td {
                        padding: 5px;
                        font-size: 13px;
                        vertical-align: middle;
                        span.number {
                            font-size: 18px;
                            font-weight: bold;
                            background: linear-gradient(180deg, #40ad72 51.48%, #b659fe 51.49%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }
                    }
                }
            }
        }
    }
}
//  ******************************
// wingo Section end
//  ******************************
//  ******************************
// Wintrx Section start
//  ******************************
.Wintrx {
    .button_color_big_small {
        width: 100%;
        height: 30px;
        text-transform: capitalize;
        border-radius: 25px;
        color: #fff;
        &:nth-child(1) {
            background-color: #fd565c;
        }
        &:nth-child(2) {
            background-color: #c76286;
        }
    }

    .multiple_win {
        border: 1px solid $oragnecolor;
        color: $oragnecolor;
        padding: 0px 5px;
        border-radius: 4px;
        font-size: 13px;
    }
    .active.multiple_win {
        font-size: 13px;
        background: $primarycolor;
        border: 1px solid $oragnecolor;
        color: #fff;
    }
    .multiple_winredom {
        padding: 4px 15px;
        border-radius: 4px;
        font-size: 13px;
    }
    .button_color_game {
        padding: 4px 32px;
        color: #fff;
        &:nth-child(1) {
            background: #40ad72;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            box-shadow: 0 0.05333rem 0.16rem rgba(244, 157, 157, 0.144);
        }
        &:nth-child(2) {
            background: #b659fe;
            border-radius: 5px;
            box-shadow: 0 0.05333rem 0.16rem rgba(244, 157, 157, 0.192);
        }
        &:nth-child(3) {
            background: #fd565c;
            border-top-left-radius: 5px;
            box-shadow: 0 0.05333rem 0.16rem #f49d9d;
            border-bottom-right-radius: 5px;
        }
    }
    .Marquee_Tag {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        width: 100%;
        height: 39px;
        padding-inline: 0.26667rem;
        color: #303a4c;
        font-size: 0.32rem;
        border-radius: 99rem;
        background: #fff;
        box-shadow: 0 0.10667rem 0.16rem #d0d0ed4d;
        padding: 9px;
        border-radius: 27px;
        margin-bottom: 10px;
        margin-top: 10px;
        .Notice p {
            width: 90%;
            height: -moz-fit-content;
            height: fit-content;
            max-height: 22px;
            line-height: 1.1;
            animation: marquee 7s linear infinite;
            display: flex;
            justify-content: column;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            word-break: break-all;
            font-size: 10px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 0px;
        }

        .sound_icon {
            font-size: 20px;
            color: $footerdeactive;
        }
        .details {
            padding: 2px 13px;
            border-radius: 27px;
            flex-shrink: 0;
            gap: 11px;
            margin-left: auto;
            border: none;
            background: $buttoncolor;
            font-size: 22px;
            align-items: center;
            display: flex;
            height: 25px;
            justify-content: center;
            button {
                font-size: 11px;
                align-items: center;
                gap: 4px;
            }
        }
    }
    .allbefore:before {
        position: absolute;
        left: 0px;
        width: 100%;
        top: -8px;
        background: $primarycolor;
        height: 250px;
        content: "";
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        z-index: -1;
    }
    .buttonall {
        background: var(--bgcolor);
        font-weight: 600;
        color: #fff;
        text-align: center;
        padding: 2px 10px;
        margin-top: 10px;
    }
    .points_left_right {
        position: relative;
        &:after,
        &:before {
            border: 5px solid $oragnecolor;
            content: "";
            width: 20px;
            height: 20px;
            background-color: #f9f3f3;
            position: absolute;
            top: 37%;
            border-radius: 50%;
        }
        &:after {
            left: -8px;
        }
        &:before {
            right: -8px;
        }
    }

    .windgo_tabs {
        .react-tabs__tab-panel--selected {
            display: block;
            overflow: hidden;
            border-radius: 7px;
        }
        .react-tabs {
            ul {
                .react-tabs__tab--selected {
                    box-shadow:
                        inset 0 -0.02667rem 0.08rem #ffdfdb,
                        inset 0 0.02667rem 0.08rem #ea6b66;
                    background: $primarycolor;
                    border-color: transparent;
                    border-radius: 10px;
                    color: black;
                    .tabs_windgo > * {
                        color: #fff;
                    }
                }
                li {
                    padding: 7px;
                    width: 75px;
                }
            }
        }
        .icon_rounded {
            font-size: 30px;
            opacity: 0.8;
        }
        p {
            font-size: 11px;
            color: #666;
            margin-bottom: 0px;
            line-height: 15px;
            span {
                display: block;
            }
        }
        .border_offset {
            outline: 10px dotted #f9f3f3;
            outline-offset: -6px;
            margin: 9px 0px;
        }
        .tab_active {
            .Howtoplay {
                h5 {
                    color: #fff;
                    font-size: 12px;
                    border: 1px solid #fff;
                    border-radius: 25px;
                    padding: 3px 10px;
                }
                p {
                    margin-top: 5px;
                    color: #fff;
                    font-size: 12px;
                    text-align: start;
                }
            }
            .Countdown_timer {
                .countdown_time {
                    .countdown {
                        padding: 0px 5px;
                        background: #fff;
                        &:nth-child(1) {
                            border-radius: 1px;
                            border-top-left-radius: 6px;
                        }
                        &:nth-child(5) {
                            border-radius: 1px;
                            border-bottom-right-radius: 6px;
                        }
                    }
                }
            }
        }
    }
    .history {
        .react-tabs__tab-list {
            display: flex;
            border-bottom: 0px;
            justify-content: space-between;
            .react-tabs__tab {
                padding: 0px;
                button {
                    border-radius: 5px;
                    border: 1px solid $oragnecolor;
                    color: $oragnecolor;
                    font-size: 13px;
                    width: 100px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .react-tabs__tab--selected {
                background: $primarycolor;
                border-color: transparent;
                color: black;
                border-radius: 5px;
                &:after {
                    content: none;
                }
                button {
                    width: 100px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    color: #fff;
                    font-size: 13px;
                }
            }
        }
        .history_table {
            thead {
                tr {
                    th {
                        padding: 5px;
                        font-weight: 600;
                        font-size: 14px;
                        background: $primarycolor;
                        color: #fff;
                        &:nth-child(1) {
                            border-top-left-radius: 5px;
                        }

                        &:nth-child(4) {
                            border-top-right-radius: 5px;
                        }
                    }
                }
            }
            tbody {
                td {
                    padding: 5px;
                    font-size: 13px;
                    vertical-align: middle;
                    span.number {
                        font-size: 18px;
                        font-weight: bold;
                        background: linear-gradient(180deg, #40ad72 51.48%, #b659fe 51.49%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }
                }
            }
        }
    }
}
//  ******************************
// Wintrx Section end
//  ******************************

//  ******************************
// Gamek3 Section end
//  ******************************
.Gamek3 {
    .cube_box {
        border: 14px solid #00b977;
        border-radius: 8px;
        width: 90%;
        margin: auto;
        .box {
            background: #003c26;
            border: 3px solid #000;
            height: 85px;
            width: 92px;
            padding: 8px;
            &.before_box {
                position: relative;
                &:before {
                    position: absolute;
                    content: "";
                    left: 0px;
                    border-left: 15px solid #ffe7e7;
                    border-right: 10px solid #00b977;
                    top: 23px;
                    z-index: 999;
                    border: solid 16px;
                    border-color: #fff0 #73353500 #fff0 #00b977;
                }
            }
            &.after_box {
                position: relative;
                &:after {
                    position: absolute;
                    content: "";
                    right: 0px;
                    top: 23px;
                    z-index: 999;
                    border: solid 16px;
                    border-color: #ffffff00 #00b977 #ffffff00 #ffffff00;
                }
            }
        }
        .box_custum {
            background: url("../src/assets/images/dicefive.png");
            background-size: contain;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-position: 82%;
        }
    }
    .number_btn {
        width: 40px;
        height: 40px;
        color: #fff;
        background-color: rgba(182, 89, 254, 0.5);
        border-radius: 5px;
    }
    .number_btn_sec {
        width: 40px;
        height: 40px;
        color: #fff;
        background-color: var(--red-color);
        border-radius: 5px;
        width: 100%;
        padding: 3px;
    }
    .number_btn_sec_same {
        color: #fff;
        width: 100%;
        padding: 3px;
        background-image: $primarycolor;
        border-radius: 5px;
    }
    .button_color_big_small {
        width: 100%;
        height: 30px;
        text-transform: capitalize;
        border-radius: 25px;
        color: #fff;
        &:nth-child(1) {
            background-color: #fd565c;
        }
        &:nth-child(2) {
            background-color: #c76286;
        }
    }

    .multiple_win {
        border: 1px solid $oragnecolor;
        color: $oragnecolor;
        padding: 0px 5px;
        border-radius: 4px;
        font-size: 13px;
    }
    .active.multiple_win {
        font-size: 13px;
        background: $primarycolor;
        border: 1px solid $oragnecolor;
        color: #fff;
    }
    .multiple_winredom {
        padding: 4px 15px;
        border-radius: 4px;
        font-size: 13px;
    }
    .button_color_game {
        padding: 4px 32px;
        color: #fff;
        &:nth-child(1) {
            background: #40ad72;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            box-shadow: 0 0.05333rem 0.16rem rgba(244, 157, 157, 0.144);
        }
        &:nth-child(2) {
            background: #b659fe;
            border-radius: 5px;
            box-shadow: 0 0.05333rem 0.16rem rgba(244, 157, 157, 0.192);
        }
        &:nth-child(3) {
            background: #fd565c;
            border-top-left-radius: 5px;
            box-shadow: 0 0.05333rem 0.16rem #f49d9d;
            border-bottom-right-radius: 5px;
        }
    }
    .Marquee_Tag {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        width: 100%;
        height: 39px;
        padding-inline: 0.26667rem;
        color: #303a4c;
        font-size: 0.32rem;
        border-radius: 99rem;
        background: #fff;
        box-shadow: 0 0.10667rem 0.16rem #d0d0ed4d;
        padding: 9px;
        border-radius: 27px;
        margin-bottom: 10px;
        margin-top: 10px;
        .Notice p {
            width: 90%;
            height: -moz-fit-content;
            height: fit-content;
            max-height: 22px;
            line-height: 1.1;
            animation: marquee 7s linear infinite;
            display: flex;
            justify-content: column;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            word-break: break-all;
            font-size: 10px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 0px;
        }

        .sound_icon {
            font-size: 20px;
            color: $footerdeactive;
        }
        .details {
            padding: 2px 13px;
            border-radius: 27px;
            flex-shrink: 0;
            gap: 11px;
            margin-left: auto;
            border: none;
            background: $buttoncolor;
            font-size: 22px;
            align-items: center;
            display: flex;
            height: 25px;
            justify-content: center;
            button {
                font-size: 11px;
                align-items: center;
                gap: 4px;
            }
        }
    }
    .allbefore:before {
        position: absolute;
        left: 0px;
        width: 100%;
        top: -8px;
        background: $primarycolor;
        height: 250px;
        content: "";
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        z-index: -1;
    }
    .buttonall {
        background: var(--bgcolor);
        font-weight: 600;
        color: #fff;
        text-align: center;
        padding: 2px 10px;
        margin-top: 10px;
    }
    .points_left_right {
        position: relative;
        &:after,
        &:before {
            border: 5px solid $oragnecolor;
            content: "";
            width: 20px;
            height: 20px;
            background-color: #f9f3f3;
            position: absolute;
            top: 37%;
            border-radius: 50%;
        }
        &:after {
            left: -8px;
        }
        &:before {
            right: -8px;
        }
    }
    .windgo_tabs {
        .react-tabs {
            .react-tabs__tab-list {
                border-bottom: none;
                margin: 0 0 10px;
                padding: 0;
                display: flex;
                gap: 7px;
                justify-content: center;
            }
            ul {
                .react-tabs__tab--selected {
                    box-shadow:
                        inset 0 -0.02667rem 0.08rem #ffdfdb,
                        inset 0 0.02667rem 0.08rem #ea6b66;
                    background: $primarycolor;
                    border-color: transparent;
                    border-radius: 5px;
                    color: #fff;
                    .tabs_windgo > * {
                        color: #fff;
                    }
                }
                li {
                    padding: 7px;
                    width: 75px;
                    background: #f0f0f0;
                    border-radius: 5px;
                }
            }
        }
        .icon_rounded {
            font-size: 30px;
            opacity: 0.8;
        }
        p {
            font-size: 11px;
            color: #666;
            margin-bottom: 0px;
            line-height: 15px;
            span {
                display: block;
            }
        }

        .tab_active {
            .Howtoplay {
                h5 {
                    color: $oragnecolor;
                    font-size: 12px;
                    border: 1px solid $oragnecolor;
                    border-radius: 25px;
                    padding: 3px 10px;
                }
                p {
                    margin-top: 5px;
                    color: #fff;
                    font-size: 12px;
                    text-align: start;
                }
            }
            .Countdown_timer {
                .countdown_time {
                    .countdown {
                        padding: 0px 5px;
                        background: #e3e3e3;
                        &:nth-child(1) {
                            border-radius: 1px;
                            border-top-left-radius: 6px;
                        }
                        &:nth-child(5) {
                            border-radius: 1px;
                            border-bottom-right-radius: 6px;
                        }
                    }
                }
            }
        }
    }
    .Total {
        padding: 0px 10px 10px;

        .bet_x {
            font-size: 10px;
        }
        .btn_color_bigsmall {
            margin: 0px 5px;
            width: 69px;
            padding: 2px 10px;
            font-size: 12px;
            line-height: 1.4;
            background-color: var(--bg-color-game);
        }
    }
    .history {
        .react-tabs__tab-list {
            display: flex;
            border-bottom: 0px;
            justify-content: space-between;
            .react-tabs__tab {
                padding: 0px;
                button {
                    border-radius: 5px;
                    border: 1px solid $oragnecolor;
                    color: $oragnecolor;
                    font-size: 13px;
                    width: 100px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .react-tabs__tab--selected {
                background: $primarycolor;
                border-color: transparent;
                color: black;
                border-radius: 5px;
                &:after {
                    content: none;
                }
                button {
                    width: 100px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    color: #fff;
                    font-size: 13px;
                }
            }
        }
        .history_table {
            thead {
                tr {
                    th {
                        padding: 5px;
                        font-weight: 600;
                        font-size: 14px;
                        background: $primarycolor;
                        color: #fff;
                        &:nth-child(1) {
                            border-top-left-radius: 5px;
                        }

                        &:nth-child(4) {
                            border-top-right-radius: 5px;
                        }
                    }
                }
            }
            tbody {
                td {
                    padding: 5px;
                    font-size: 13px;
                    vertical-align: middle;
                    span.number {
                        font-size: 18px;
                        font-weight: bold;
                        background: linear-gradient(180deg, #40ad72 51.48%, #b659fe 51.49%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }
                }
            }
        }
    }
}
//  ******************************
// Gamek3 Section end
//  ******************************
// Game5d 5d Section end
//  ******************************
.Game5d {
    .cube_box {
        border: 14px solid #00b977;
        border-radius: 8px;
        width: 90%;
        margin: auto;
        .box {
            background: #003c26;
            border: 3px solid #000;
            height: 85px;
            width: 92px;
            padding: 8px;
            &.before_box {
                position: relative;
                &:before {
                    position: absolute;
                    content: "";
                    left: 0px;
                    border-left: 15px solid #ffe7e7;
                    border-right: 10px solid #00b977;
                    top: 23px;
                    z-index: 999;
                    border: solid 16px;
                    border-color: #fff0 #73353500 #fff0 #00b977;
                }
            }
            &.after_box {
                position: relative;
                &:after {
                    position: absolute;
                    content: "";
                    right: 0px;
                    top: 23px;
                    z-index: 999;
                    border: solid 16px;
                    border-color: #ffffff00 #00b977 #ffffff00 #ffffff00;
                }
            }
        }
        .box_custum {
            background: url("../src/assets/images/dicefive.png");
            background-size: contain;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-position: 82%;
        }
    }
    .number_btn {
        width: 40px;
        height: 40px;
        color: #fff;
        background-color: rgba(182, 89, 254, 0.5);
        border-radius: 5px;
    }
    .number_btn_sec {
        width: 40px;
        height: 40px;
        color: #fff;
        background-color: var(--red-color);
        border-radius: 5px;
        width: 100%;
        padding: 3px;
    }
    .number_btn_sec_same {
        color: #fff;
        width: 100%;
        padding: 3px;
        background-image: $primarycolor;
        border-radius: 5px;
    }
    .button_color_big_small {
        width: 100%;
        height: 30px;
        text-transform: capitalize;
        border-radius: 25px;
        color: #fff;
        &:nth-child(1) {
            background-color: #fd565c;
        }
        &:nth-child(2) {
            background-color: #c76286;
        }
    }

    .multiple_win {
        border: 1px solid $oragnecolor;
        color: $oragnecolor;
        padding: 0px 5px;
        border-radius: 4px;
        font-size: 13px;
    }
    .active.multiple_win {
        font-size: 13px;
        background: $primarycolor;
        border: 1px solid $oragnecolor;
        color: #fff;
    }
    .multiple_winredom {
        padding: 4px 15px;
        border-radius: 4px;
        font-size: 13px;
    }
    .button_color_game {
        padding: 4px 32px;
        color: #fff;
        &:nth-child(1) {
            background: #40ad72;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            box-shadow: 0 0.05333rem 0.16rem rgba(244, 157, 157, 0.144);
        }
        &:nth-child(2) {
            background: #b659fe;
            border-radius: 5px;
            box-shadow: 0 0.05333rem 0.16rem rgba(244, 157, 157, 0.192);
        }
        &:nth-child(3) {
            background: #fd565c;
            border-top-left-radius: 5px;
            box-shadow: 0 0.05333rem 0.16rem #f49d9d;
            border-bottom-right-radius: 5px;
        }
    }
    .Marquee_Tag {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        width: 100%;
        height: 39px;
        padding-inline: 0.26667rem;
        color: #303a4c;
        font-size: 0.32rem;
        border-radius: 99rem;
        background: #fff;
        box-shadow: 0 0.10667rem 0.16rem #d0d0ed4d;
        padding: 9px;
        border-radius: 27px;
        margin-bottom: 10px;
        margin-top: 10px;
        .Notice p {
            width: 90%;
            height: -moz-fit-content;
            height: fit-content;
            max-height: 22px;
            line-height: 1.1;
            animation: marquee 7s linear infinite;
            display: flex;
            justify-content: column;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            word-break: break-all;
            font-size: 10px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 0px;
        }

        .sound_icon {
            font-size: 20px;
            color: $footerdeactive;
        }
        .details {
            padding: 2px 13px;
            border-radius: 27px;
            flex-shrink: 0;
            gap: 11px;
            margin-left: auto;
            border: none;
            background: $buttoncolor;
            font-size: 22px;
            align-items: center;
            display: flex;
            height: 25px;
            justify-content: center;
            button {
                font-size: 11px;
                align-items: center;
                gap: 4px;
            }
        }
    }
    .allbefore:before {
        position: absolute;
        left: 0px;
        width: 100%;
        top: -8px;
        background: $primarycolor;
        height: 250px;
        content: "";
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        z-index: -1;
    }
    .buttonall {
        background: var(--bgcolor);
        font-weight: 600;
        color: #fff;
        text-align: center;
        padding: 2px 10px;
        margin-top: 10px;
    }
    .points_left_right {
        position: relative;
        &:after,
        &:before {
            border: 5px solid $oragnecolor;
            content: "";
            width: 20px;
            height: 20px;
            background-color: #f9f3f3;
            position: absolute;
            top: 37%;
            border-radius: 50%;
        }
        &:after {
            left: -8px;
        }
        &:before {
            right: -8px;
        }
    }
    .windgo_tabs {
        .react-tabs {
            .react-tabs__tab-list {
                border-bottom: none;
                margin: 0 0 10px;
                padding: 0;
                display: flex;
                gap: 7px;
                justify-content: center;
            }
            ul {
                .react-tabs__tab--selected {
                    box-shadow:
                        inset 0 -0.02667rem 0.08rem #ffdfdb,
                        inset 0 0.02667rem 0.08rem #ea6b66;
                    background: $primarycolor;
                    border-color: transparent;
                    border-radius: 5px;
                    color: #fff;
                    .tabs_windgo > * {
                        color: #fff;
                    }
                }
                li {
                    padding: 7px;
                    width: 75px;
                    background: #f0f0f0;
                    border-radius: 5px;
                }
            }
        }
        .icon_rounded {
            font-size: 30px;
            opacity: 0.8;
        }
        p {
            font-size: 11px;
            color: #666;
            margin-bottom: 0px;
            line-height: 15px;
            span {
                display: block;
            }
        }

        .tab_active {
            .Howtoplay {
                h5 {
                    color: $oragnecolor;
                    font-size: 12px;
                    border: 1px solid $oragnecolor;
                    border-radius: 25px;
                    padding: 3px 10px;
                }
                p {
                    margin-top: 5px;
                    color: #fff;
                    font-size: 12px;
                    text-align: start;
                }
            }
            .Countdown_timer {
                .countdown_time {
                    .countdown {
                        padding: 0px 5px;
                        background: #e3e3e3;
                        &:nth-child(1) {
                            border-radius: 1px;
                            border-top-left-radius: 6px;
                        }
                        &:nth-child(5) {
                            border-radius: 1px;
                            border-bottom-right-radius: 6px;
                        }
                    }
                }
            }
        }
    }
    .Total {
        padding: 0px 10px 10px;

        .bet_x {
            font-size: 10px;
        }
        .btn_color_bigsmall {
            margin: 0px 5px;
            width: 69px;
            padding: 2px 10px;
            font-size: 12px;
            line-height: 1.4;
            background-color: var(--bg-color-game);
        }
    }
    .history {
        .react-tabs__tab-list {
            display: flex;
            border-bottom: 0px;
            justify-content: space-between;
            .react-tabs__tab {
                padding: 0px;
                button {
                    border-radius: 5px;
                    border: 1px solid $oragnecolor;
                    color: $oragnecolor;
                    font-size: 13px;
                    width: 100px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .react-tabs__tab--selected {
                background: $primarycolor;
                border-color: transparent;
                color: black;
                border-radius: 5px;
                &:after {
                    content: none;
                }
                button {
                    width: 100px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    color: #fff;
                    font-size: 13px;
                }
            }
        }
        .history_table {
            thead {
                tr {
                    th {
                        padding: 5px;
                        font-weight: 600;
                        font-size: 14px;
                        background: $primarycolor;
                        color: #fff;
                        &:nth-child(1) {
                            border-top-left-radius: 5px;
                        }

                        &:nth-child(4) {
                            border-top-right-radius: 5px;
                        }
                    }
                }
            }
            tbody {
                td {
                    padding: 5px;
                    font-size: 13px;
                    vertical-align: middle;
                    span.number {
                        font-size: 18px;
                        font-weight: bold;
                        background: linear-gradient(180deg, #40ad72 51.48%, #b659fe 51.49%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }
                }
            }
        }
    }
}
//  ******************************
// game 5d Section end
//  ******************************

//  ******************************
// withdraw history Section start
//  ******************************
.Withdrawhistory {
    .datepicker {
        font-size: 10px;
    }
    .history_type {
        .react-tabs__tab {
            width: 29%;
            display: inline-block;
            border: transparent;
            border-bottom: none;
            bottom: -1px;
            position: relative;
            list-style: none;
            cursor: pointer;
            background-color: #fff;
            box-shadow: 0px 0px 10px 0px rgba(196, 196, 196, 0.59);
            padding: 7px;
            margin: 0px 5px;
            border-radius: 5px;
            &:after {
                content: none;
            }
        }
        .react-tabs__tab-list {
            border-bottom: transparent;
            margin: 10px 0 10px;
            padding: 0;
        }
        .react-tabs__tab--selected {
            background: $primarycolor;
            border-color: #aaa;
            color: rgb(255, 255, 255);
            border-radius: 4px;
        }
    }
    background: #f7f8ff;
    height: 100%;
    width: 100%;
    .custom-model-popup {
        position: fixed;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: flex-end;
        visibility: hidden;
        opacity: 0;
        // transition: opacity 0.3s ease, visibility 0s linear 0.3s;
        z-index: 1000; /* Ensure it appears on top of other content */
        height: 100%;
        bottom: -600px;
        transition: all 1s ease;
    }

    .custom-model-popup.open {
        visibility: visible;
        opacity: 1;
        bottom: 0px;
    }

    .popup-content {
        background-color: white;
        padding: 10px;
        height: 300px;
        border-radius: 10px 10px 0 0;
        width: 100%;
        box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.3);
    }

    .withdrawal_status {
        height: 100px;
        padding-left: 0px;
        margin-top: 20px;
        li {
            padding: 10px;
            border-bottom: 1px solid #cccccc42;
            font-size: 13px;
            font-weight: bold;
            color: #929292;
            &.highlighted {
                border-bottom: 1px solid #cccccc42;
                color: #000;
            }
            &:last-of-type {
                border-bottom: 0px;
            }
        }
    }
    .history_type {
        .All_type {
            background-color: #fff;
            box-shadow: 0px 0px 10px 0px rgb(196 196 196 / 59%);
            padding: 7px;
            width: 100%;
            margin: 0px 5px;
            border-radius: 5px;
            p {
                margin-bottom: 0px;
                font-size: 12px;
                color: #acafc2;
            }
            .svg {
                font-size: 12px;
                color: #acafc2;
            }
        }
    }
    /* CustomModal.css */
    .custom-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .custom-modal {
        background-color: #fff;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        max-width: 80%;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 18px;
        color: #555;
    }

    .modal-content {
        /* Add your modal content styling here */
    }
}
//  ******************************
// withdrawhistory Section end
//  ******************************
//  ******************************
// withdraw Section start
//  ******************************


.withdraw {
    .amountbox{
        padding:10px 0px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
        border-radius: 7px;
        cursor: pointer;
        font-size: 20px;
        color: #000;
        h4{
        font-size: 20px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }   
    }
    .card_design_custum {
        background: $primarycolor;
        border-radius: 8px;
        margin-top: 8px;
        color: #fff;
        position: relative;
        z-index: 1;
        &:before {
            content: "";
            background: url("../src/assets/images/wave.png");
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0px;
            left: 0px;
            top: 0px;
            background-size: cover;
            z-index: -1;
            opacity: 0.2;
        }
    }
    .chip {
        width: 20px;
        height: 15px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

//  ******************************
// withdraw Section end
//  ******************************
//  ******************************
// Deposithistory Section start
//  ******************************
.Deposithistory {
    .datepicker {
        font-size: 10px;
    }
    .history_type {
        .react-tabs__tab {
            width: 46%;
            display: inline-block;
            border: transparent;
            border-bottom: none;
            bottom: -1px;
            position: relative;
            list-style: none;
            cursor: pointer;
            box-shadow: 0px 0px 10px 0px rgba(196, 196, 196, 0.59);
            padding: 7px 3px;
            margin: 8px 5px;
            white-space: nowrap;
            background: #fff;
            border-radius: 5px;
            div {
                width: 78px;
                justify-content: center;
                font-size: 12px;
            }
            &:after {
                content: none;
            }
        }
        .react-tabs__tab-list {
            border-bottom: transparent;
            margin: 10px 0 10px;
            padding: 0;
        }
        .react-tabs__tab--selected {
            background: $primarycolor;
            border-color: #aaa;
            color: rgb(255, 255, 255);
            border-radius: 4px;
        }
    }
    background: #f7f8ff;
    height: 100%;
    width: 100%;
    .custom-model-popup {
        position: fixed;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: flex-end;
        visibility: hidden;
        opacity: 0;
        // transition: opacity 0.3s ease, visibility 0s linear 0.3s;
        z-index: 1000; /* Ensure it appears on top of other content */
        height: 100%;
        bottom: -600px;
        transition: all 1s ease;
    }

    .custom-model-popup.open {
        visibility: visible;
        opacity: 1;
        bottom: 0px;
    }

    .popup-content {
        background-color: white;
        padding: 10px;
        height: 300px;
        border-radius: 10px 10px 0 0;
        width: 100%;
        box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.3);
    }

    .withdrawal_status {
        height: 100px;
        padding-left: 0px;
        margin-top: 20px;
        li {
            padding: 10px;
            border-bottom: 1px solid #cccccc42;
            font-size: 13px;
            font-weight: bold;
            color: #929292;
            &.highlighted {
                border-bottom: 1px solid #cccccc42;
                color: #000;
            }
            &:last-of-type {
                border-bottom: 0px;
            }
        }
    }
    .history_type {
        .All_type {
            background-color: #fff;
            box-shadow: 0px 0px 10px 0px rgb(196 196 196 / 59%);
            padding: 7px;
            width: 100%;
            margin: 0px 5px;
            border-radius: 5px;
            p {
                margin-bottom: 0px;
                font-size: 12px;
                color: #acafc2;
            }
            .svg {
                font-size: 12px;
                color: #acafc2;
            }
        }
    }
    /* CustomModal.css */
    .custom-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .custom-modal {
        background-color: #fff;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        max-width: 80%;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 18px;
        color: #555;
    }

    .modal-content {
        /* Add your modal content styling here */
    }
}
.deposit_status{
    padding: 2px 6px;
    border-radius: 5px;
    color: #fff;
}
//  ******************************
// Deposithistory Section end
//  ******************************

.bg-color {
    background: #f7f8ff;
}
.tab_grid {
    background: $primarycolor;
    padding: 7px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    img {
        width: 33px;
        margin: auto;
    }
    p{
    margin-bottom:0px;
}
}
.tab_grid_right {
    background: $primarycolor;
    padding: 7px 14px;
    border-radius: 18px;
    box-shadow:
        0 -0.05333rem 0.13333rem #fff6f4 inset,
        0 0.10667rem 0.21333rem 0.05333rem #d0d0ed5c;
    .right_tab {
        color: #fff;
        h2 {
            font-size: 16px;
            font-weight: 600;
        }
        p {
            font-size: 13px;
            span {
                display: block;
                font-size: 11px;
            }
        }
    }
}

//  ******************************
// todayearning Section start
//  ******************************
.todayearning {
    .ranking_page {
        background-image: url("../src/assets/images/ranking.png");
        width: 100%;
        height: 145px;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        top: 26px;
        .winner {
            position: relative;
            width: 33%;
            .winner2 {
                position: absolute;
                left: 12px;
                top: -26px;
                picture {
                    .kingsivercrown {
                        position: absolute;
                        transform: rotate(-31deg);
                        width: 40px;
                        left: 6px;
                        top: -16px;
                    }
                }
                .game_id {
                    margin-top: 13px;
                    color: #fff;
                    font-weight: bold;
                    font-size: 12px;
                }
                .amount {
                    font-size: 11px;
                    font-weight: bold;
                    color: #fff;
                    align-items: center;
                    background: $buttoncolor;
                    padding: 4px;
                    border-radius: 25px;
                    margin-top: 5px;
                }
            }
            .winner1 {
                position: absolute;
                left: 10px;
                top: -56px;
                picture {
                    .kingsivercrown {
                        position: absolute;
                        transform: rotate(-36deg);
                        width: 40px;
                        left: 0px;
                        top: -19px;
                    }
                }
                .game_id {
                    margin-top: 13px;
                    color: #fff;
                    font-weight: bold;
                    font-size: 12px;
                }
                .amount {
                    font-size: 11px;
                    font-weight: bold;
                    color: #fff;
                    align-items: center;
                    background: $buttoncolor;
                    padding: 4px;
                    border-radius: 25px;
                    margin-top: 5px;
                }
            }
            .winner3 {
                position: absolute;
                left: 10px;
                top: -12px;
                picture {
                    .kingsivercrown {
                        position: absolute;
                        transform: rotate(-31deg);
                        width: 40px;
                        left: 5px;
                        top: -22px;
                    }
                }
                .game_id {
                    margin-top: 13px;
                    color: #fff;
                    font-weight: bold;
                    font-size: 12px;
                }
                .amount {
                    font-size: 11px;
                    font-weight: bold;
                    color: #fff;
                    align-items: center;
                    background: $buttoncolor;
                    padding: 4px;
                    border-radius: 25px;
                    margin-top: 5px;
                }
            }
        }
    }
}

//  ******************************
// todayearning Section end
//  ******************************

//  ******************************
// Footer Start
//  ******************************
.footer {
    display: flex;
    position: fixed;
    background: #fff;
    width: 100%;
    height: 60px;
    bottom: 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .gap_custum {
        gap: 30px;
        margin: auto;
    }

    .footerlink {
        position: relative;
        text-decoration: none;
        width: 50px;
        height: 50px;
        display: inline-block;
        .footericon {
            svg {
                font-size: 20px;
                color: $footeractive;
            }
        }
        span {
            font-size: 12px;
            color: $footerdeactive;
        }
    }
    .active {
        .footericon {
            &:before {
                position: absolute;
                background: $buttoncolor;
                box-shadow: inset 0px 2px 4px 0px rgb(255 255 255 / 50%);
                width: 50px;
                height: 50px;
                border-radius: 50%;
                top: -25px;
                content: "";
                z-index: -6;
            }
            svg {
                font-size: 24px;
                color: #fff;
                position: relative;
                top: -10px;
            }
        }
    }
}
//  ******************************
// Footer end
//  ******************************
